import './util/handleError';
import './util/setupServiceWorker';

import VConsole from 'vconsole';
import lottie from 'lottie-web';
import React from './lib/teact/teact';
import TeactDOM from './lib/teact/teact-dom';
import * as aniData from './assets/loading.json';

import { getActions, getGlobal } from './global';
import updateWebmanifest from './util/updateWebmanifest';
import { setupBeforeInstallPrompt } from './util/installPrompt';
import { IS_INSTALL_PROMPT_SUPPORTED } from './util/environment';
import './global/init';
import { ANIMATION_LEVEL_MAX, DEBUG } from './config';

import App from './App';

import './styles/index.scss';
import useLang from './hooks/useLang';
import type { LangCode, ThemeKey } from './types';
import switchTheme from './util/switchTheme';
import { setLanguage } from './util/langProvider';
import { clearLocalStorage } from './util/toolUtils';
import { checkIfPushSupported, requestPermission } from './util/notifications';

// const vconsole = new VConsole();
window.userRights = {
  showMember: false,
  createChannel: false,
  createGroup: false,
  addContact: false,
};
const loginInfo = localStorage.getItem("loginInfo");
if (loginInfo) {
  const search = window.location.search.slice(1).split('&');
  let tgid = 0;
  for (let i = 1; i < search.length; i++) {
    const temp = search[i].split('=');
    if (temp[0] === 'tgid') {
      tgid = Number(temp[1]);
    }
  }
  const userAuth = localStorage.getItem('loginInfo');
  if (userAuth && JSON.parse(userAuth).id && tgid) {
    if (String(tgid) !== JSON.parse(userAuth).id) {
      localStorage.clear();
      setInterval(() => {
        if (!localStorage.getItem('user_auth')) {
          window.location.reload();
        }
      }, 100);
    }
  }
}

if (loginInfo) {
  const root = document.getElementById('root');
  const dom = document.createElement('div');
  dom.classList.add('loginMask');
  if (document.location.href.includes('dark')) {
    dom.classList.add('dark');
  }
  // const loadingImg = document.createElement('img');
  // loadingImg.src = window.location.href.includes('dark') ? loadingD : loadingL;
  // loadingImg.classList.add('loginMask__LoadingText');
  // dom.appendChild(loadingImg);
  const ani = document.createElement('div');
  const lottieContent = lottie.loadAnimation({
    container: ani,
    renderer: 'svg',
    loop: true,
    animationData: aniData,
  });
  dom.appendChild(ani);
  root?.appendChild(dom);
  lottieContent.play();
}
window.addEventListener('message', (msg: {
  data: {
    type: string;
    msg: any;
  };
}) => {
  if (msg.data.type === 'group') {
    window.goSomeWhere(msg.data.msg, 'group');
  } else {
    window.$getMessageFromApp(msg.data.type, msg.data.msg);
  }
});
window.onload = function(){
  window.addEventListener('click', function(){
    const supportNotification = checkIfPushSupported();
    if(supportNotification instanceof Promise){
      supportNotification.then((result)=> {
        if(!result) {
          requestPermission();
        }
      })
    } else {
      if(!supportNotification) {
        requestPermission();
      }
    }
  });
}
window.$getMessageFromApp = (action: string, message?: Login | string | ThemeKey | Array<string> | UserRights | { id: string; accesshash: string }) => {
  switch (action) {
    case 'Logout':
      // eslint-disable-next-line
      clearLocalStorage();
      // window.location.href = window.location.href.split('?')[0];
      getActions().signOut();
      setInterval(() => {
        if (!localStorage.getItem('loginInfo')) {
          window.location.reload(); 
        }
      }, 100);
      break;
    case 'Login':
      if (message && message instanceof Object && 'Token' in message) {
        // eslint-disable-next-line no-console
        let id = message.partnerId;
        if (!id) id = 100n;
        // eslint-disable-next-line
        getActions().setAuthUserMetaLogin({ id: message.UserID, bytes: message.Token, partnerId: id });
      }
      break;
    case 'changeModel':
      if (message) {
        // dark || light
        const global = getGlobal();
        getActions().setSettingOption({ theme: message });
        getActions().setSettingOption({ shouldUseSystemTheme: false });
        switchTheme(message as ThemeKey, global.settings.byKey.animationLevel === ANIMATION_LEVEL_MAX);
      }
      break;
    case 'lang':
      if (message) {
        // en || zh-hant-raw
        setLanguage(message as LangCode, () => {
          getActions().setSettingOption({ language: message });
        });
        console.log('Index setLanguage');
      }
      break;
    case 'nameChange':
      // if (!message) return;
      // getActions().updateProfile({
      //   firstName: message,
      // });
      break;
    case 'openGroup':
      if (Array.isArray(message)) {
        if (typeof getActions().openUrl !== 'function') {
          setTimeout(() => {
            window.$getMessageFromApp('openGroup', message);
          }, 1000);
          return;
        }
        message.forEach((item) => {
          getActions().openUrl({ url: item });
        });
      }
      break;
    case 'joinGroupById':
      if (typeof message === 'string') {
        getActions().joinChannel({ chatId: message });
      }
      break;
    case 'addUser':
      if (typeof getActions().importContactByIdentifyAuto !== 'function') {
        setTimeout(() => {
          window.$getMessageFromApp(action, message);
        }, 100);
        return;
      }
      if (Array.isArray(message)) {
        message.forEach((item) => {
          getActions().importContactByIdentifyAuto({
            identification: item,
            partnerId: 100n,
          });
        }, []);
      }
      break;
    case 'getGroups':
      if (message instanceof Array && message.length) {
        markGroups('joinedGroup', message);
      }
      break;
    case 'addGroups':
      if (typeof message === 'string') {
        markGroups('addGroups', [message]);
      }
      break;
    case 'delGroups':
      if (typeof message === 'string') {
        markGroups('delGroups', [message]);
      }
      break;
    case 'rights':
      if (message instanceof Object && 'showMember' in message) {
        // window.userRights = {
        //   ...window.userRights,
        //   ...message,
        // };
      }
      break;
    case 'pin':
      if (message instanceof Object && 'id' in message) {
        // getActions().toggleChatPinned({ id: message.id });
        // ChatPinned(String(message.id), message.accesshash, true);
        getActions().chatPinned({ id: message.id, accessHash: message.accesshash, shouldBePinned: true });
      }
      break;
    case 'unPin':
      if (message instanceof Object && 'id' in message) {
        // getActions().toggleChatPinned({ id: message.id });
        // ChatPinned(String(message.id), message.accesshash, true);
        getActions().chatPinned({ id: message.id, accessHash: message.accesshash, shouldBePinned: false });
      }
      break;
  }
};
function markGroups(type: string, message: string[]) {
  return;
  const userAgent = navigator.userAgent;
  const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
  if (isiOS && window.webkit) {
    window.webkit?.messageHandlers?.TGWebview.postMessage({
      action: type,
      msg_status: message,
    });
  }
  if (isAndroid) {
    if (window.Android) {
      window.Android.joinedGroup(type, message);
    }
  }
}
window.click_back = () => {
  const bodyEml = document.getElementById('Main') as HTMLElement;
  const rightElem = document.getElementById('RightColumn-wrapper');
  const settingElem = document.getElementById('Settings');
  const imgEiew = document.querySelector('#MediaViewer > div.media-viewer-head > button') as HTMLElement;
  // eslint-disable-next-line
  const newChatElem = document.querySelector('#NewChat > div.Transition__slide--active > div > div.left-header > button') as HTMLElement;
  const leftButton = document.querySelector('#LeftMainHeader > div.DropdownMenu > button') as HTMLElement;
  if (imgEiew) {
    imgEiew.dispatchEvent(new Event('mousedown'));
    imgEiew.click();
  } else if (rightElem && rightElem.classList.length === 0) {
    if (bodyEml.classList.length !== 0) {
      const backButton = document.querySelector('#RightColumn > div.RightHeader > button') as HTMLElement;
      backButton.dispatchEvent(new Event('mousedown'));
      backButton.click();
    } else if (bodyEml.classList.length === 0) {
      // eslint-disable-next-line max-len
      const selectMsg = document.querySelector('#MiddleColumn > div.messages-layout > div.Transition.slide > div > div.middle-column-footer > div.MessageSelectToolbar.with-composer.shown > div > button') as HTMLElement;
      // eslint-disable-next-line max-len
      const selectMsgAction = document.querySelector('#MiddleColumn > div.messages-layout > div.Transition.slide > div > div.middle-column-footer > div.MessageSelectToolbar.with-composer.shown > div > div.MessageSelectToolbar-actions') as HTMLElement;
      if (selectMsg && selectMsgAction) {
        selectMsg.dispatchEvent(new Event('mousedown'));
        selectMsg.click();
      } else {
        // eslint-disable-next-line max-len
        const backButton = document.querySelector('#MiddleColumn > div.messages-layout > div.MiddleHeader > div.Transition.slide-fade > div.Transition__slide--active > div.back-button > button') as HTMLElement;
        backButton.dispatchEvent(new Event('mousedown'));
        backButton.click();
      }
    }
  } else if (settingElem) {
    // eslint-disable-next-line max-len
    const backButton = document.querySelector('#Settings > div.Transition__slide--active > div.left-header > button') as HTMLElement;
    backButton.dispatchEvent(new Event('mousedown'));
    backButton.click();
  } else if (newChatElem) {
    newChatElem.dispatchEvent(new Event('mousedown'));
    newChatElem.click();
  } else if (leftButton && leftButton.title === 'Return to chat list') {
    leftButton.dispatchEvent(new Event('mousedown'));
    leftButton.click();
  }
};

window.isChatpage = () => {
  const rightElem = document.getElementById('RightColumn-wrapper');
  const settingElem = document.getElementById('Settings');
  const imgEiew = document.querySelector('#MediaViewer > div.media-viewer-head > button');
  // eslint-disable-next-line
  const newChatElem = document.querySelector('#NewChat > div.Transition__slide--active > div > div.left-header > button');
  const leftButton = document.querySelector('#LeftMainHeader > div.DropdownMenu > button');

  // eslint-disable-next-line
  if (rightElem && rightElem.classList.length === 0 || settingElem || imgEiew || newChatElem || (leftButton && (leftButton as HTMLElement).title == 'Return to chat list')) {
    return true;
  } else {
    return false;
  }
};
window.$gototgmsg = (index: number) => {
  const userAgent = navigator.userAgent;
  const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1;
  if (isiOS) {
    if (window.webkit
      && typeof window.webkit === 'object'
      && window.webkit.messageHandlers
      && typeof window.webkit.messageHandlers === 'object'
      && window.webkit.messageHandlers.TGWebview
      && typeof window.webkit.messageHandlers.TGWebview === 'object'
      && window.webkit.messageHandlers.TGWebview.postMessage
      && typeof window.webkit.messageHandlers.TGWebview.postMessage === 'function'
    ) {
      window.webkit.messageHandlers?.TGWebview.postMessage(
        {
          action: 'tgmsg',
          about: 'tg',
          msg_status: index,
        },
      );
    } else {
      window.parent.postMessage({
        type: 'unreadCount',
        count: index,
      }, '*');
    }
  } else if (isAndroid) {
    if (window.Android && window.Android.SHOW_READ) {
      window.Android.SHOW_READ(index);
    }
  }
  if (window && window.hasOwnProperty('parent')) {
    window.parent.postMessage({
      type: 'unreadCount',
      count: index,
    }, '*');
  }
};

console.log('env', process.env.NODE_ENV);

if (DEBUG) {
  // eslint-disable-next-line no-console
  console.log('>>> INIT');
}
// 设置前提示
if (IS_INSTALL_PROMPT_SUPPORTED) {
  setupBeforeInstallPrompt();
}
// 全局数据初始化
getActions().init();

if (DEBUG) {
  // eslint-disable-next-line no-console
  console.log('>>> START INITIAL RENDER');
}

// 更新网络清单
updateWebmanifest();

TeactDOM.render(
  <App />,
  document.getElementById('root')!,
);

if (DEBUG) {
  // eslint-disable-next-line no-console
  console.log('>>> FINISH INITIAL RENDER');
}

if (DEBUG) {
  document.addEventListener('dblclick', () => {
    // eslint-disable-next-line no-console
    console.warn('GLOBAL STATE', getGlobal());
  });
}
