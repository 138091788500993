import { AppInfoProps } from "../components/auth/AuthUserNameLogin";
import { getActions } from "../global";
import { queryMerchantInfo } from "../global/http/api";
import { LangCode } from "../types";
import { setLanguage } from "./langProvider";
type DomainFlags = '91wsapp' | 'Enterprise' | 'FlyChat';
type IconProps = {
    id: string,
    iconUrl: string,
    isMainIcon: boolean,
    sizes: string | null | undefined,
    type: string,
    rel: string,
}
export type DomainProps = {
    pageTitle: string;
    manifestLink:string;
    icons: Array<IconProps>;
    description: string
}
type DomainMapProps = {
    [key in DomainFlags]: DomainProps;
};
export const DomainMap:DomainMapProps = {
    '91wsapp': {
        pageTitle: '91wsapp',
        manifestLink: 'https://app.liveim.com/dwimages/91wsapp.com.json',
        icons: [
            {
                id: 'the-link-svg',
                iconUrl: './91wsapp_favicon.svg',
                isMainIcon: false,
                sizes: null,
                type: 'image/svg+xml',
                rel: 'icon',
            },
            {
                id: 'the-app-touch-icon-precomposed',
                iconUrl: './app-touch-91wsapp.png',
                isMainIcon: false,
                sizes: null,
                type: '',
                rel: 'apple-touch-icon-precomposed',
            },
            {
                id: 'the-apple-icon180',
                iconUrl: './app-touch-91wsapp.png',
                isMainIcon: false,
                sizes: '180x180',
                type: '',
                rel: 'apple-touch-icon',
            },
            {
                iconUrl: './91wsapp-favicon.ico',
                isMainIcon: true,
                id: 'the-link-icon',
                sizes: null,
                type:'image/x-icon',
                rel: 'alternate icon',
            },
            {
                iconUrl: './91wsapp-favicon.ico',
                isMainIcon: false,
                id: 'the-link-shortcut-icon',
                sizes: null,
                type:'image/x-icon',
                rel: 'shortcut icon',
            },
            {
                id: 'the-link-icon16',
                iconUrl: './91wsapp-favicon-16x16.png',
                isMainIcon: false,
                sizes: '16x16',
                type:'image/png',
                rel: 'icon',
            },
            {
                id: 'the-link-icon32',
                iconUrl: './91wsapp-favicon-32x32.png',
                isMainIcon: false,
                sizes: '32x32',
                type:'image/png',
                rel: 'icon',
            },
            {
                id: 'the-link-icon192',
                iconUrl: './91swapp-favicon-192x192.png',
                isMainIcon: false,
                sizes: '192x192',
                type:'image/png',
                rel: 'icon',
            }
        ],
        description: '91wsapp is a cloud-based mobile and desktop messaging app with a focus on security and speed',
    },
    'FlyChat': {
        pageTitle: 'FlyChat',
        manifestLink: 'https://app.liveim.com/dwimages/flychat.com.json',
        icons: [
            {
                id: 'the-link-svg',
                iconUrl: './flychat_favicon.svg',
                isMainIcon: false,
                sizes: null,
                type: 'image/svg+xml',
                rel: 'icon',
            },
            {
                id: 'the-app-touch-icon-precomposed',
                iconUrl: './app-touch-flychat.png',
                isMainIcon: false,
                sizes: null,
                type: '',
                rel: 'apple-touch-icon-precomposed',
            },
            {
                id: 'the-apple-icon180',
                iconUrl: './app-touch-flychat.png',
                isMainIcon: false,
                sizes: '180x180',
                type: '',
                rel: 'apple-touch-icon',
            },
            {
                iconUrl: './flychat-favicon.ico',
                isMainIcon: true,
                id: 'the-link-icon',
                sizes: null,
                type:'image/x-icon',
                rel: 'alternate icon',
            },
            {
                iconUrl: './flychat-favicon.ico',
                isMainIcon: false,
                id: 'the-link-shortcut-icon',
                sizes: null,
                type:'image/x-icon',
                rel: 'shortcut icon',
            },
            {
                id: 'the-link-icon16',
                iconUrl: './flychat-favicon-16x16.png',
                isMainIcon: false,
                sizes: '16x16',
                type:'image/png',
                rel: 'icon',
            },
            {
                id: 'the-link-icon32',
                iconUrl: './flychat-favicon-32x32.png',
                isMainIcon: false,
                sizes: '32x32',
                type:'image/png',
                rel: 'icon',
            },
            {
                id: 'the-link-icon192',
                iconUrl: './flychat-favicon-192x192.png',
                isMainIcon: false,
                sizes: '192x192',
                type:'image/png',
                rel: 'icon',
            }
        ],
        description: 'FlyChat is a cloud-based mobile and desktop messaging app with a focus on security and speed',
    },
    'Enterprise': {
        pageTitle: 'IM',
        manifestLink: 'https://app.liveim.com/dwimages/liveim.com.json',
        icons: [
            {
                id: 'the-link-svg',
                iconUrl: './favicon.svg',
                isMainIcon: false,
                sizes: null,
                type: 'image/svg+xml',
                rel: 'icon',
            },
            {
                id: 'the-apple-icon180',
                iconUrl: './app-touch-icon.png',
                isMainIcon: false,
                sizes: '180x180',
                type: '',
                rel: 'apple-touch-icon',
            },
            {
                id: 'the-link-icon',
                iconUrl: './im-favicon.ico',
                isMainIcon: true,
                sizes: null,
                type:'image/x-icon',
                rel: 'alternate icon',
            },
            {
                id: 'the-app-touch-icon-precomposed',
                iconUrl: './app-touch-icon.png',
                isMainIcon: false,
                sizes: null,
                type: '',
                rel: 'apple-touch-icon-precomposed',
            },
            {
                iconUrl: './favicon.ico',
                isMainIcon: false,
                id: 'the-link-shortcut-icon',
                sizes: null,
                type:'image/x-icon',
                rel: 'shortcut icon',
            },
            {
                id: 'the-link-icon16',
                iconUrl: './favicon-16x16.png',
                isMainIcon: false,
                sizes: '16x16',
                type:'image/png',
                rel: 'icon',
            },
            {
                id: 'the-link-icon32',
                iconUrl: './favicon-32x32.png',
                isMainIcon: false,
                sizes: '32x32',
                type:'image/png',
                rel: 'icon',
            },
            {
                id: 'the-link-icon192',
                iconUrl: './favicon-192x192.png',
                isMainIcon: false,
                sizes: '192x192',
                type:'image/png',
                rel: 'icon',
            }
        ],
        description: 'IM is a cloud-based mobile and desktop messaging app with a focus on security and speed'
    }
}
export const Toast = (msg: string,duration: number) => {
    duration=isNaN(duration)?3000:duration;
    var m = document.createElement('div');
    m.innerHTML = msg;
    m.className = 'toast-tips';
    document.body.appendChild(m);
    setTimeout(function() {
        var d = 0.5;
        m.style.opacity = '0';
        setTimeout(function() { document.body.removeChild(m) }, d * 1000);
    }, duration);
}
export const isIOS = ()=> {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}
export const countSlashes = (str: string) =>{
    return (str.match(/\//g) || []).length;
}
export const isAddressBarVisible = function() {
    let windowHeight = window.innerHeight;
    let documentHeight = document.documentElement.clientHeight;
    return windowHeight < documentHeight;
};
export const getBottomBarHeight = () => {
    let innerHeight = window.innerHeight;
    let outerHeight = window.outerHeight;

    let statusBarHeight = outerHeight - innerHeight;
    return statusBarHeight/2;
}
export const changeAppInfoCache= async(data: any, flag?: string)=> {
    if(flag == 'language'){
        await getActions().setSettingOption({ language: data.language });
        if(data && data.language){
            setLanguage(data.language as LangCode);
        }
    }
    await localStorage.setItem('appInfo', JSON.stringify(data));
}
export const changeDefaultLanguage= async(data: any, appInfo:DomainProps)=> {
    await getActions().setSettingOption({ language: data.language });
    if(data && data.language){
        setLanguage(data.language as LangCode);
    }
    await localStorage.setItem('appInfo', JSON.stringify(data));
    await RegisterDomainLabels(appInfo);
}
export const queryMerchantUtil = ()=> {
    queryMerchantInfo(async (data)=> {
        const domainMap = checkDomainMap(data.merchantNameEn || data.merchantName);
        const hisAppInfo = localStorage.getItem("appInfo");
        let appInfo:DomainProps = {
          pageTitle: data.descriptionTitle,
          // manifestLink: '',
          icons: domainMap?.icons || [],
          description: data.description,
          manifestLink: domainMap?.manifestLink || '',
        }
        if(hisAppInfo){
            const hisAppInfoMap = JSON.parse(hisAppInfo) as any;
            if(hisAppInfoMap.merchantDomain != data.merchantDomain) {
                let newAppInfoMap = {} as any;
                for(let property in data) {
                    if( data[property] != hisAppInfoMap[property] || !hisAppInfoMap[property]){
                        newAppInfoMap[property] = data[property];
                    }
                }
                if(hisAppInfoMap.language != data.language){
                    changeAppInfoCache(newAppInfoMap, 'language');
                } else {
                    changeAppInfoCache(newAppInfoMap);
                }
            }
            await RegisterDomainLabels(appInfo);
        } else {
            changeDefaultLanguage(data, appInfo);
        }
      });
};
export const queryMerchantInfoUtil = ()=> {
    // const appInfo = localStorage.getItem("appInfo");
    // if(appInfo) {
    //     const appInfoMap = JSON.parse(appInfo);
    //     if(window.location.host != appInfoMap.merchantDomain){
    //         queryMerchantUtil();
    //     }
    // } else {

    // }
    queryMerchantUtil();
}
export const getDomainMap = (key: DomainFlags): DomainProps | undefined=> {
    return DomainMap[key];
}
export const checkDomainMap = (key: string): DomainProps | undefined=> {
    // if(window.origin.indexOf('50235') > -1){
    //     return getDomainMap('FlyChat');
    // }
    // if(window.origin.indexOf('liveim') > -1) {
    //     return getDomainMap('LiveIM');
    // }
    return getDomainMap(key);
}
export const checkIsMetaLogin = ()=> {
    const loginInfo = localStorage.getItem("loginInfo")
    return window.location.href.includes('ThirdLogin') || Boolean(loginInfo)
}
export const installHeaderIcon = (icons: Array<IconProps>) => {
    if(icons.length > 0) {
        for(let i=0; i< icons.length; i++) {
            const iconObj = document.getElementById(icons[i].id);
            const iconLink = iconObj?.getAttribute('href');
            if(iconLink == icons[i].iconUrl){
                return ;
            } else {
                if(iconObj){
                    document.head.removeChild(iconObj);
                }
                const newIconObj = document.createElement('link');
                newIconObj.href = icons[i].iconUrl;
                if(!icons[i].isMainIcon && icons[i].sizes){
                    newIconObj.setAttribute('sizes', icons[i].sizes);
                }
                newIconObj.rel = icons[i].rel;
                newIconObj.type = icons[i].type;
                newIconObj.id=icons[i].id;
                document.head.appendChild(newIconObj);
            }
        }
    }
// <link rel="icon" type="image/png" sizes="16x16" href="./favicon-16x16.png">
//   <link rel="icon" type="image/png" sizes="32x32" href="./favicon-32x32.png">
//   <link rel="icon" type="image/png" sizes="192x192" href="./<%= htmlWebpackPlugin.options.mainIcon %>.png">
//   <link rel="alternate icon" href="./favicon.ico" type="image/x-icon">
}
export const installHeaderLinks = (manifestLink: string) => {
    const manifestObj = document.getElementById('the-manifest-placeholder');
    const prepareLink = manifestObj?.getAttribute('href');
    if(manifestLink) {
        if(manifestLink == prepareLink){
            return ;
        } else {
            if(manifestObj){
                document.head.removeChild(manifestObj);
            }
            const newManifestObj = document.createElement('link');
            newManifestObj.href = manifestLink;
            newManifestObj.rel = 'manifest';
            newManifestObj.id='the-manifest-placeholder';
            document.head.appendChild(newManifestObj);
        }
    }
}
export const installHeaderDescription = (description: string) => {
    const descriptionObj = document.getElementById('the-meta-description');
    const content = descriptionObj?.getAttribute('content');
    if(description == content){
        return ;
    } else {
        if(descriptionObj){
            document.head.removeChild(descriptionObj);
        }
        const newDesobj = document.createElement('meta');
        newDesobj.name = 'description';
        newDesobj.content = description;
        newDesobj.id='the-meta-description';
        document.head.appendChild(newDesobj);
    }
}
export const installAppTitle = (metaId: string,appTitle: string)=> {
    const metaObj = document.getElementById(metaId);
    const content = metaObj?.getAttribute('content');
    if(appTitle == content){
        return ;
    } else {
        if(metaObj){
            document.head.removeChild(metaObj);
        }
        const newDesobj = document.createElement('meta');
        newDesobj.id=metaId;
        newDesobj.name = metaId;
        newDesobj.content = appTitle;
        document.head.appendChild(newDesobj);
    }
}
export const openShareLink = (url: string, splitStr: string)=> {
    const shareArr = url.split(splitStr);
    if(shareArr[1] && typeof shareArr[1] === 'string'){
        const newUrl = 'tg.cc/'+shareArr[1];
        getActions().openTelegramLink({url: newUrl});
    }
}
export const getParamsByUrl = (paramName: string)=> {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    return params.get(paramName);
}
export const RegisterDomainLabels= async (domainMap: DomainProps)=> {
    document.title = domainMap.pageTitle;
    await installAppTitle('apple-mobile-web-app-title',domainMap.pageTitle);
    await installAppTitle('application-name',domainMap.pageTitle);
    await installAppTitle('mobile-web-app-title',domainMap.pageTitle);
    await installHeaderDescription(domainMap.description);
    await installHeaderIcon(domainMap.icons);
    await installHeaderLinks(domainMap.manifestLink);
}
export const setDefaultThemeAtions = (userId: string | undefined)=> {
    getActions().setThemeSettings({
        theme: 'light',
        background: undefined,
        backgroundColor: '#e6ebee',
        patternColor: 'hsla(202.5000000000001, 18.025210084033617%, 60.66666666666667%, .4)',
        currentUserId: userId
    });
}
export const setDefaultThemes = (userId: string | undefined,callback: (() => void) | undefined)=> {
    const themes = localStorage.getItem('themes');
    if(themes){
      const  themesMap = JSON.parse(themes) as any;
      if(themesMap.userId && themesMap.userId == userId){
        if(themesMap.dark){
            getActions().setThemeSettings({
                theme: 'dark',
                ...themesMap.dark
            });
          }
          if(themesMap.light){
            getActions().setThemeSettings({
                theme: 'light',
                ...themesMap.light
            });
        }
      } else {
        setDefaultThemeAtions(userId);
      }
    //   callback && callback()
    } else {
        setDefaultThemeAtions(userId);
    }
}
export const setStorageByKey = (key: string, values: string | object)=> {
    const caches = typeof values === 'string' ? values : JSON.stringify(values);
    localStorage.setItem(key, caches);
}
export const clearStorageByKey = (key: string)=> {
    const clearCache = localStorage.getItem(key);
    if(clearCache){
        localStorage.removeItem(key);
    }
}
export const clearLocalStorage = ()=> {
    const langCode = localStorage.getItem('lang');
    const themes = localStorage.getItem('themes');
    const appInfo = localStorage.getItem('appInfo');
    localStorage.clear();
    if(langCode){
        localStorage.setItem('lang', langCode);
    }
    if(themes) {
        const themesMap = JSON.parse(themes) as any;
        localStorage.setItem('themes', JSON.stringify(themesMap));
    }
    if(appInfo) {
        const appInfoMap = JSON.parse(appInfo) as any;
        localStorage.setItem('appInfo', JSON.stringify(appInfoMap));
    }
}

export const getDefaultAppInfo = (url: string):AppInfoProps=> {
    if(url.indexOf('91wsapp') > -1){
        return {
            merchantLogoUrl: 'https://app.liveim.com/dwimages/609411267583365120.jpeg',
            merchantName: "91wsapp",
            autofillInviteCode: '',
            isAutofillInviteCode: 0,
            language: 'en'
        }
    } else if(url.indexOf('flychat') > -1){
        return {
            merchantLogoUrl: 'https://app.liveim.com/dwimages/581854650516197376.jpeg',
            merchantName: 'FlyChat',
            autofillInviteCode: '',
            isAutofillInviteCode: 0,
            language: 'classic-zh-cn',
        }
    } else {
        return {
            merchantLogoUrl: 'https://app.liveim.com/dwimages/581515605386809344.jpeg',
            merchantName: 'Enterprise',
            autofillInviteCode: '',
            isAutofillInviteCode: 0,
            language: 'classic-zh-cn'
        }
    }
}
export async function requestMicrophonePermission(callback: (flag: boolean)=> void) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('麦克风权限已被授予');
      callback && callback(true);
    } catch (err) {
      console.error('麦克风权限被拒绝', err);
      callback && callback(false)
    }
}
export async function hasMicrophoneDelection(callback: ((arg0: { isSuccess: boolean; stream: MediaStream | null; }) => any) | undefined) {
    // return new Promise((resolve, reject) => {
    //   // 尝试获取麦克风设备
    //   navigator.mediaDevices.getUserMedia({ audio: true })
    //     .then((stream) => {
    //       let result = {
    //         isSuccess: true,
    //         stream
    //       }
    //       // 用户已授权
    //       resolve(result);
    //     })
    //     .catch(err => {
    //       let result = {
    //         isSuccess: false,
    //         stream: null
    //       }
    //       // 用户拒绝或未授权
    //       resolve(result);
    //     });
    // });
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log('麦克风权限已被授予', stream);
        let result = {
            isSuccess: true,
            stream
        }
        callback && callback(result);
    } catch (err) {
        console.error('麦克风权限被拒绝', err);
        let result = {
            isSuccess: true,
            stream: null
        }
        callback && callback(result)
    }
}
export async function invokeSDKRequestCall(
    requestCall: any,
    params: {
        userId: string;
        isVideo?: boolean | undefined;
    }){
    const hasAudioPermission = window.Enterprise_SDK.hasPermissions('audio');
    if(hasAudioPermission){
      requestCall(params);
    } else {
      window.Enterprise_SDK.requestNecessaryPermissions("audio");
    }
}
export function closeMicro(response: any) {
    let audioTrack = response.stream.getAudioTracks()[0];
    audioTrack.stop();
    audioTrack = null;
}
export const urlB64ToUint8Array = function(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  export function isElementInViewport(element: any) {
    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    // 判断元素是否在可见区域的垂直范围内
    const isInVerticalViewport = rect.top <= viewportHeight && rect.bottom >= 0;
    // 判断元素是否在可见区域的水平范围内
    const isInHorizontalViewport = rect.left <= (window.innerWidth
      || document.documentElement.clientWidth) && rect.right >= 0;
    return isInVerticalViewport && isInHorizontalViewport;
  }
