import { addActionHandler, setGlobal } from '../../index';

import { MAIN_THREAD_ID } from '../../../api/types';

import {
  exitMessageSelectMode, replaceThreadParam, updateCurrentMessageList,
} from '../../reducers';
import { selectCurrentMessageList } from '../../selectors';
import { closeLocalTextSearch } from './localSearch';

// 打开会话窗口
addActionHandler('openChat', (global, actions, payload) => {
  // debugger
  const {
    id,
    threadId = MAIN_THREAD_ID,
    type = 'thread',
    shouldReplaceHistory = false,
  } = payload;

  // 选择当前消息列表
  const currentMessageList = selectCurrentMessageList(global);

  if (global.premiumModal?.promo && global.premiumModal?.isOpen) {
    global = {
      ...global,
      premiumModal: {
        ...global.premiumModal,
        isOpen: false,
      },
    };
  }

  if (!currentMessageList
    || (
      currentMessageList.chatId !== id
      || currentMessageList.threadId !== threadId
      || currentMessageList.type !== type
    )) {
    if (id) {
      // 替换线程参数
      global = replaceThreadParam(global, id, threadId, 'replyStack', []);
    }

    // 退出消息选择模式
    global = exitMessageSelectMode(global);
    // 关闭本地文本搜索
    global = closeLocalTextSearch(global);

    global = {
      ...global,
      isStatisticsShown: false,
      messages: {
        ...global.messages,
        contentToBeScheduled: undefined,
      },
      ...(id !== global.forwardMessages.toChatId && {
        forwardMessages: {},
      }),
    };
  }

  return updateCurrentMessageList(global, id, threadId, type, shouldReplaceHistory);
});

addActionHandler('openPreviousChat', (global) => {
  return updateCurrentMessageList(global, undefined);
});

//
addActionHandler('openChatWithInfo', (global, actions, payload) => {
  // debugger
  setGlobal({
    ...global,
    isChatInfoShown: true,
  });

  actions.openChat(payload);
});

addActionHandler('openChatWithDraft', (global, actions, payload) => {
  const { chatId, text, files } = payload;

  if (chatId) {
    actions.openChat({ id: chatId });
  }

  return {
    ...global,
    requestedDraft: {
      chatId,
      text,
      files,
    },
  };
});

addActionHandler('resetChatCreation', (global) => {
  return {
    ...global,
    chatCreation: undefined,
  };
});

addActionHandler('setNewChatMembersDialogState', (global, actions, payload) => {
  return {
    ...global,
    newChatMembersProgress: payload,
  };
});

addActionHandler('setChangeGroupUserPermissionsState', (global, actions, payload) => {
  return {
    ...global,
    changeGroupUserPermissionsType: payload,
  };
});

addActionHandler('openNextChat', (global, actions, payload) => {
  const { targetIndexDelta, orderedIds } = payload;

  const { chatId } = selectCurrentMessageList(global) || {};

  if (!chatId) {
    actions.openChat({ id: orderedIds[0] });
    return;
  }

  const position = orderedIds.indexOf(chatId);

  if (position === -1) {
    return;
  }
  const nextId = orderedIds[position + targetIndexDelta];

  actions.openChat({ id: nextId, shouldReplaceHistory: true });
});

addActionHandler('openDeleteChatFolderModal', (global, actions, payload) => {
  const { folderId } = payload;
  return {
    ...global,
    deleteFolderDialogModal: folderId,
  };
});

addActionHandler('closeDeleteChatFolderModal', (global) => {
  return {
    ...global,
    deleteFolderDialogModal: undefined,
  };
});

// 设置创建群ID
addActionHandler('setNewChatId', (global, actions, payload) => {
  // debugger
  return {
    ...global,
    newChatId: payload,
  };
});
