import type { ChangeEvent } from 'react';

import monkeyPath from '../../assets/monkey.svg';

import type { FC } from '../../lib/teact/teact';
import React, {
  memo, useCallback, useEffect, useLayoutEffect, useRef, useState,
} from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';

import type { GlobalState } from '../../global/types';
import type { LangCode } from '../../types';
import type { ApiCountryCode } from '../../api/types';

import { IS_SAFARI, IS_TOUCH_ENV } from '../../util/environment';
import { preloadImage } from '../../util/files';
import preloadFonts from '../../util/fonts';
import { pick } from '../../util/iteratees';
import { formatPhoneNumber, getCountryCodesByIso, getCountryFromPhoneNumber } from '../../util/phoneNumber';
import { setLanguage } from '../../util/langProvider';
import useLang from '../../hooks/useLang';
import useFlag from '../../hooks/useFlag';
import useLangString from '../../hooks/useLangString';
import { getSuggestedLanguage } from './helpers/getSuggestedLanguage';
import { postCheckInviteCode, postForceInviteCode } from '../../http/request'

import Button from '../ui/Button';
import Checkbox from '../ui/Checkbox';
import InputText from '../ui/InputText';
import Loading from '../ui/Loading';
import CountryCodeInput from './CountryCodeInput';

type StateProps = Pick<GlobalState, (
  'connectionState' | 'authState' |
  'authPhoneNumber' | 'authIsLoading' |
  // 'authIsLoadingQrCode' |
  'authError' |
  'authRememberMe' | 'authNearestCountry'
  // | 'authIsLoadingUserNameLogin'
)> & {
  language?: LangCode;
  phoneCodeList: ApiCountryCode[];
  forceInviteCode?: string;
};

const MIN_NUMBER_LENGTH = 7;

let isPreloadInitiated = false;

const AuthPhoneNumber: FC<StateProps> = ({
  connectionState,
  authState,
  authPhoneNumber,
  authIsLoading,
  // authIsLoadingQrCode,
  authError,
  authRememberMe,
  authNearestCountry,
  // authIsLoadingUserNameLogin,
  phoneCodeList,
  language,
  forceInviteCode,
}) => {
  const {
    goToAuthUserNameLogin,
    setAuthPhoneNumber,
    setAuthRememberMe,
    loadNearestCountry,
    // loadCountryList,
    clearAuthError,
    // goToAuthQrCode,
    setSettingOption,
    setTemporaryInviteCode,
    setTemporaryRecommendCode,
  } = getActions();

  const lang = useLang();
  // eslint-disable-next-line no-null/no-null
  const inputRef = useRef<HTMLInputElement>(null);
  const inviteInputRef = useRef<HTMLInputElement>(null);
  const referralInputRef = useRef<HTMLInputElement>(null);
  const suggestedLanguage = getSuggestedLanguage();

  // const continueText = useLangString(suggestedLanguage, 'ContinueOnThisLanguage');
  const [country, setCountry] = useState<ApiCountryCode | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [isTouched, setIsTouched] = useState(false);
  const [lastSelection, setLastSelection] = useState<[number, number] | undefined>();
  const [isLoading, markIsLoading, unmarkIsLoading] = useFlag();

  const [nowTemporaryInviteCode, setNowTemporaryInviteCode] = useState('')
  const [nowTemporaryReferralCode, setNowTemporaryReferralCode] = useState('')
  const [isRightInviteCode, setIsRightInviteCode] = useState(true)
  const [isRightReferralCode, setIsRightReferralCode] = useState(true)

  const fullNumber = country ? `+${country.countryCode} ${phoneNumber || ''}` : phoneNumber;
  // 下一步按钮的显示和隐藏判断
  const canSubmit = fullNumber && fullNumber.replace(/[^\d]+/g, '').length >= MIN_NUMBER_LENGTH;

  useEffect(() => {
    if (!IS_TOUCH_ENV) {
      inputRef.current!.focus();
    }
  }, [country]);

  useEffect(() => {
    if (connectionState === 'connectionStateReady' && !authNearestCountry) {
      loadNearestCountry();
    }
  }, [connectionState, authNearestCountry, loadNearestCountry]);

  useEffect(() => {
    if (connectionState === 'connectionStateReady') {
      // loadCountryList({ langCode: language });
    }
  }, [connectionState, language]);

  useEffect(() => {
    if (authNearestCountry && phoneCodeList && !country && !isTouched) {
      setCountry(getCountryCodesByIso(phoneCodeList, authNearestCountry)[0]);
    }
  }, [country, authNearestCountry, isTouched, phoneCodeList]);

  // 解析完整手机号
  const parseFullNumber = useCallback((newFullNumber: string) => {
    if (!newFullNumber.length) {
      setPhoneNumber('');
    }

    const suggestedCountry = phoneCodeList && getCountryFromPhoneNumber(phoneCodeList, newFullNumber);

    // Any phone numbers should be allowed, in some cases ignoring formatting
    const selectedCountry = !country
      || (suggestedCountry && suggestedCountry.iso2 !== country.iso2)
      || (!suggestedCountry && newFullNumber.length)
      ? suggestedCountry
      : country;

    if (!country || !selectedCountry || (selectedCountry && selectedCountry.iso2 !== country.iso2)) {
      setCountry(selectedCountry);
    }
    setPhoneNumber(formatPhoneNumber(newFullNumber, selectedCountry));
  }, [phoneCodeList, country]);

  // const handleLangChange = useCallback(() => {
  //   markIsLoading();

  //   void setLanguage(suggestedLanguage, () => {
  //     unmarkIsLoading();

  //     setSettingOption({ language: suggestedLanguage });
  //   });
  // }, [markIsLoading, setSettingOption, suggestedLanguage, unmarkIsLoading]);

  useEffect(() => {
    if (phoneNumber === undefined && authPhoneNumber) {
      parseFullNumber(authPhoneNumber);
    }
  }, [authPhoneNumber, phoneNumber, parseFullNumber]);

  useLayoutEffect(() => {
    if (inputRef.current && lastSelection) {
      inputRef.current.setSelectionRange(...lastSelection);
    }
  }, [lastSelection]);

  // 是否是刚刚粘贴进去
  const isJustPastedRef = useRef(false);
  // 手动粘贴
  const handlePaste = useCallback(() => {
    isJustPastedRef.current = true;
    requestAnimationFrame(() => {
      isJustPastedRef.current = false;
    });
  }, []);

  const handleCountryChange = useCallback((value: ApiCountryCode) => {
    setCountry(value);
    setPhoneNumber('');
  }, []);

  // 手机号码输入框出现变化
  const handlePhoneNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (authError) {
      clearAuthError();
    }

    // This is for further screens. We delay it until user input to speed up the initial loading.
    if (!isPreloadInitiated) {
      isPreloadInitiated = true;
      preloadFonts();
      void preloadImage(monkeyPath);
    }

    const { value, selectionStart, selectionEnd } = e.target;
    setLastSelection(
      selectionStart && selectionEnd && selectionEnd < value.length
        ? [selectionStart, selectionEnd]
        : undefined,
    );

    setIsTouched(true);

    const shouldFixSafariAutoComplete = (
      IS_SAFARI && country && fullNumber !== undefined
      && value.length - fullNumber.length > 1 && !isJustPastedRef.current
    );
    parseFullNumber(shouldFixSafariAutoComplete ? `${country!.countryCode} ${value}` : value);
  }, [authError, clearAuthError, country, fullNumber, parseFullNumber]);

  // 邀请码输入框出现变化
  // const handleInviteCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   const len = value.length
  //   setNowTemporaryInviteCode(value)
  //   setIsRightInviteCode(true)
  //   // if(len >= 6) {
  //   //   checkInviteCode(value)
  //   // } else {
  //   //   setIsRightInviteCode(true)
  //   // }
  // }, [])

  // 推荐码输入框出现变化
  // const handleReferralCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.target;
  //   const len = value.length
  //   setNowTemporaryReferralCode(value)
  //   setIsRightReferralCode(true)
  //   // if(len >= 6) {
  //   //   checkReferralCode(value)
  //   // } else {
  //   //   setIsRightReferralCode(true)
  //   // }
  // }, [])

  const handleKeepSessionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAuthRememberMe(e.target.checked);
  }, [setAuthRememberMe]);

  // 提交请求，获取验证码
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (authIsLoading) {
      return;
    }

    if (canSubmit) {
      setAuthPhoneNumber({ phoneNumber: fullNumber });
      // submitForm()
    }
  }

  // 提交表单
  // const submitForm = useCallback(async() => {
  //   if (forceInviteCode === '1') {
  //     let isSuccess = await checkInviteCode(fullNumber, nowTemporaryInviteCode)
  //     if (isSuccess) {
  //       setAuthPhoneNumber({ phoneNumber: fullNumber });
  //     }
  //   }
  //   if (forceInviteCode === '2') {
  //     let isSuccess = await checkReferralCode(fullNumber,  nowTemporaryReferralCode)
  //     if (isSuccess) {
  //       setAuthPhoneNumber({ phoneNumber: fullNumber });
  //     }
  //   }
  // }, [forceInviteCode, nowTemporaryInviteCode, nowTemporaryReferralCode])

  // // 检测邀请码是否正确
  // const checkInviteCode = useCallback((phone, code) => {
  //   // let isSuccess = await postForceInviteCode(phone, '', code)
  //   // setIsRightInviteCode(isSuccess)
  //   // isSuccess && setTemporaryInviteCode({code: code})
  //   // return isSuccess
  // }, [postForceInviteCode, setIsRightInviteCode])

  // // 检测推荐码是否正确
  // const checkReferralCode = useCallback((phone, code) => {
  //   // let isSuccess = await postForceInviteCode(phone, '', code)
  //   // setIsRightReferralCode(isSuccess)
  //   // isSuccess && setTemporaryRecommendCode({code: code})
  //   // return isSuccess
  // }, [postForceInviteCode, setIsRightReferralCode])

  const isAuthReady = authState === 'authorizationStateWaitPhoneNumber';

  const divStyleCss = `
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 18px;
    color: #f00;
    line-height: 1;
  `;

  return (
    <div id="auth-phone-number-form" className="custom-scroll">
      <div className="auth-form">
        <div id="logo" />
        {/* <h1>Telegram</h1> */}
        <h1>{lang('AppName')}</h1>
        <p className="note">{lang('StartText')}</p>
        <form action="" onSubmit={handleSubmit}>
          <CountryCodeInput
            id="sign-in-phone-code"
            value={country}
            isLoading={!authNearestCountry && !country}
            onChange={handleCountryChange}
          />
          <InputText
            ref={inputRef}
            id="sign-in-phone-number"
            label={lang('Login.PhonePlaceholder')}
            value={fullNumber}
            error={authError && lang(authError)}
            inputMode="tel"
            onChange={handlePhoneNumberChange}
            onPaste={IS_SAFARI ? handlePaste : undefined}
          />
          {/*
          {
            (forceInviteCode === '0' || forceInviteCode === '1') ?
              // Invite code 邀请码
              <InputText
                ref={inviteInputRef}
                id="sign-in-invite-code"
                label={forceInviteCode === '0' ? lang('InviteCodeOptional') : lang('InviteCodeRequired')}
                value={nowTemporaryInviteCode}
                error={authError && lang(authError)}
                inputMode="text"
                onChange={handleInviteCodeChange}
                onPaste={IS_SAFARI ? handlePaste : undefined}
              />
              :
              (forceInviteCode === '2' ?
                // Referral Code 推荐码
                <InputText
                  ref={referralInputRef}
                  id="sign-in-referral-code"
                  // label={'Referral code (Required filling）'}
                  label={lang('ReferralCodeRequired')}
                  value={nowTemporaryReferralCode}
                  error={authError && lang(authError)}
                  inputMode="text"
                  onChange={handleReferralCodeChange}
                  onPaste={IS_SAFARI ? handlePaste : undefined}
                />
              : undefined
              )
          }
          */}

          {
            !isRightInviteCode
              ? (
                <div style={divStyleCss}>
                  {/* 待翻译 */}
                  {/* Invite code absence! */}
                  {lang('InviteCodeNonExistent')}
                </div>
              )
              : ''
          }

          {
            !isRightReferralCode
              ? (
                <div style={divStyleCss}>
                  {/* 待翻译 */}
                  {/* Referral code absence! */}
                  {lang('ReferralCodeNonExistent')}
                </div>
              )
              : ''
          }
          <Checkbox
            id="sign-in-keep-session"
            // label="Keep me signed in"
            label={lang('KeepMeSignedIn')}
            checked={Boolean(authRememberMe)}
            onChange={handleKeepSessionChange}
          />
          {
            canSubmit && (
              isAuthReady ? (
                <Button type="submit" ripple isLoading={authIsLoading}>{lang('Login.Button')}</Button>
              ) : (
                <Loading />
              )
            )
          }
          {/* {
            (forceInviteCode === '1') && nowTemporaryInviteCode && canSubmit && (
              isAuthReady ? (
                <Button type="submit" ripple  isLoading={authIsLoading}>{lang('Login.Button')}</Button>
              ) : (
                <Loading />
              )
            )
          }
          {
            (forceInviteCode === '2') && nowTemporaryReferralCode && canSubmit && (
              isAuthReady ? (
                <Button type="submit" ripple  isLoading={authIsLoading}>{lang('Login.Button')}</Button>
              ) : (
                <Loading />
              )
            )
          } */}
          {/* {canSubmit && (
            isAuthReady ? (
              <Button type="submit" ripple
                disabled={(!nowTemporaryInviteCode ||
                   (nowTemporaryInviteCode.length === 6 && isRightInviteCode)) ? false : true}
                 color={ (!nowTemporaryInviteCode ||
                   (nowTemporaryInviteCode.length === 6 && isRightInviteCode)) ? 'primary' : 'disable'}
                 isLoading={authIsLoading}>{lang('Login.Next')}</Button>
            ) : (
              <Loading />
            )
          )} */}
          {/* {isAuthReady && (
            <Button isText ripple isLoading={authIsLoadingQrCode} onClick={goToAuthQrCode}>
              {lang('Login.QR.Login')}
            </Button>
          )} */}
          {isAuthReady && (
            // <Button isText ripple isLoading={authIsLoadingUserNameLogin} onClick={goToAuthUserNameLogin}>
            <Button isText ripple onClick={goToAuthUserNameLogin}>
              {/* LOG IN BY PASSWORD */}
              {lang('LoginByPassword')}
            </Button>
          )}

          {/* {suggestedLanguage && suggestedLanguage !== language && continueText && (
            <Button isText isLoading={isLoading} onClick={handleLangChange}>{continueText}</Button>
          )} */}
        </form>
      </div>
    </div>
  );
};

export default memo(withGlobal(
  (global): StateProps => {
    const {
      settings: { byKey: { language } },
      countryList: { phoneCodes: phoneCodeList },
      forceInviteCode,
    } = global;

    return {
      ...pick(global, [
        'connectionState',
        'authState',
        'authPhoneNumber',
        'authIsLoading',
        // 'authIsLoadingQrCode',
        'authError',
        'authRememberMe',
        'authNearestCountry',
        // 'authIsLoadingUserNameLogin',
      ]),
      language,
      phoneCodeList,
      forceInviteCode,
    };
  },
)(AuthPhoneNumber));
