import { loginCallback } from "../../components/auth/AuthUserNameSignUp";
import { RQUEST_URL } from "../../config";
import { getActions, getGlobal } from "../../global";
import { Toast, clearLocalStorage } from "../../util/toolUtils";
type ErrorMsgMapProps = {
    string: string
}
export const  ErrorMsgMap = {
}
const BaseUrl:string = RQUEST_URL;
let global = getGlobal();
export interface ResponseProps {
    code: number | string;
    data: object;
    msg: string
}
export interface LoginResponseProps {
    tgid: number | undefined,
    imToken: string,
    authorization: string,
}
export const HttpRquestUrl = {
    USER_LOGIN: '/5wchat/account/login',
    USER_REGISTER: '/5wchat/account/register',
    DISCOVER_LIST: '/5wchat/invite/inviteCodeDiscoveryList',
    USER_CHANGE_PASSWORD: '/5wchat/account/changeUserPassword',
    USER_REFRESH_TOKEN: '/5wchat/account/refreshToken',
    QUERY_MERCHANTINFO: '/5wchat/account/getMerchantInfo',
    QUERY_USERINFO: '/5wchat/account/getUserInfo'
}
const handleResponseInterceptor = (result: any, callback: ((errorCode: string) => void) | undefined, handleRefreshCallback: () => void) => {
    if(result && result.code == '100003') {
        userRefreshToken(handleRefreshCallback);
    } else {
        callback && callback(result);
    }
}
export async function checkTokenIsExpiration():Promise<boolean> {
    const token = checkLoginAuthorization();
    if(!token) return false;
    const response = await fetch(BaseUrl+HttpRquestUrl.DISCOVER_LIST, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
        }
    });
    const result = await response.json();
    
    return new Promise((resolve)=> {
        if (result.code === 200) {
            resolve(true);
        } else if (result.code == '100003'){
            resolve(false);
        } else {
            return (false);
        }
    })
}
const checkLoginAuthorization = ()=> {
    if(global.token){
        return global.token;
    } else {
        const  loginInfo = localStorage.getItem("loginInfo");
        if(Boolean(loginInfo)){
            const loginObj = JSON.parse(loginInfo) as LoginResponseProps;
            getActions().setToken({token: loginObj.authorization});
            return loginObj.authorization;
        }
        return undefined;
    }
}
export async function queryMerchantInfo(callback: { (result: any): void; (arg0: any): any; }) {
    let params = {
        merchantDomain: window.location.host
        // merchantDomain: 'www.91wsapp.com'
        // merchantDomain: 'www.flychat.com'
    };
    const response = await fetch(BaseUrl+HttpRquestUrl.QUERY_MERCHANTINFO, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        callback && callback(result.data);
    } 
}
/**
 * query discover list
 * */ 
export async function queryDiscoverList() {
    getActions().setLoadingData({isLoadingData: true});
    const token = checkLoginAuthorization();
    if(!token) return;
    const response = await fetch(BaseUrl+HttpRquestUrl.DISCOVER_LIST, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
        }
    });
    const result = await response.json();
    if (result.code === 200) {
        getActions().setLoadingData({isLoadingData: false});
        getActions().setDiscoveryList({discoverList: result.data.list || []});
    } else if (result.code == '100003'){
        userRefreshToken(()=> {
            queryDiscoverList();
        });
    } else {
        Toast(result.msg, 2000);
    }
}
export async function getUserInfo():Promise<any> {
    const token = checkLoginAuthorization();
    if(!token) return false;
    const response = await fetch(BaseUrl+HttpRquestUrl.QUERY_USERINFO, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':token
        }
    });
    const result = await response.json();
    return new Promise((resolve)=> {
        if (result.code === 200) {
            const loginInfo = localStorage.getItem("loginInfo");
            if(loginInfo){
                let loginInfoMap = JSON.parse(loginInfo);
                loginInfoMap.imToken = result.data.imToken;
                localStorage.setItem("loginInfo", JSON.stringify(loginInfoMap));
            }   
            resolve(result.data.imToken);
        } else if (result.code == '100003'){
            resolve(false);
        } else {
            return (false);
        }
    })
}
export async function userChangePassword(requrstUrl: string, params: { userId: string, oldPassword: string, newPassword: string}, errCallback: ((errorCode: string) => void) | undefined) {
    const token = checkLoginAuthorization();
    if(!token) return;
    const response = await fetch(BaseUrl+requrstUrl, {
        method: 'POST',
        headers: {
            'Content-Type':'application/json',
            'Accept': 'application/json',
            'Authorization':token,
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    handleResponseInterceptor(result, errCallback, ()=> {
        userChangePassword(requrstUrl, params, errCallback);
    });
   
}
export async function userLogin(requrstUrl: string, params: { username: string, password: string}, callback: ((errorCode: string) => void) | undefined) {
    const response = await fetch(BaseUrl+requrstUrl, {
        method: 'POST',
        headers: {
            'Content-Type':'application/json;charset=UTF-8',
            'Accept': 'application/json',
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        const params = {
            userId: result.data.id,
            tgid: result.data.imUserId,
            imToken: result.data.imToken,
            inviteCode: result.data.inviteCode,
            authorization: result.data.token,            
            refreshToken: result.data.refreshToken,
            isLogin: true,
            redpacketToken: result.data.redpacketToken
        }
        // getActions.setLoading();
        localStorage.setItem("loginInfo", JSON.stringify(params));
        window.location.reload();
    } else {
        callback && callback(result);
    }
}
export async function userRefreshToken(handleRefreshCallback: (() => void) | undefined){
    const loginInfo = localStorage.getItem("loginInfo");
    if(loginInfo){
        const loginInfoMap = JSON.parse(loginInfo);
        const refreshToken = loginInfoMap.refreshToken;
        let params = {
            refreshToken
        };
        const response = await fetch(BaseUrl+HttpRquestUrl.USER_REFRESH_TOKEN, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json;charset=UTF-8',
                'Accept': 'application/json',
            },
            body: JSON.stringify(params)
        });
        const result = await response.json();
        if (result.code === 200) {
            let newLoginInfoMap = {
                ...loginInfoMap,
                authorization: result.data.token,
                refreshToken: result.data.refreshToken,
                redpacketToken: result.data.redpacketToken
            }
            localStorage.setItem("loginInfo", JSON.stringify(newLoginInfoMap));
            handleRefreshCallback && handleRefreshCallback();
        } else {
            getActions().signOut();
            clearLocalStorage();
            window.location.reload();
        }
    }
}
export async function userRegister(requrstUrl: string, params: { username: string, password: string}, errCallback: { (result: any): void; (arg0: { username: string; password: string; }): any; }, callback: loginCallback) {
    const response = await fetch(BaseUrl+requrstUrl, {
        method: 'POST',
        headers: {
            'Content-Type':'application/json;charset=UTF-8',
            'Accept': 'application/json',
        },
        body: JSON.stringify(params)
    });
    const result = await response.json();
    if (result.code === 200) {
        callback && callback(params);
    } else {
        errCallback && errCallback(result);
    }
}