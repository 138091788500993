import { addActionHandler } from '../../index';
import type { ISettings, IThemeSettings, ThemeKey } from '../../../types';
import { replaceSettings, replaceThemeSettings, updateTabState } from '../../reducers';
import { getCurrentTabId } from '../../../util/establishMultitabRole';

addActionHandler('setSettingOption', (global, actions, payload?: Partial<ISettings>) => {
  return replaceSettings(global, payload);
});

addActionHandler('setSettingsScreenType', (global, action, payload) => {
  return {
    ...global,
    settingsScreenType: payload,
  };
});

addActionHandler('setIsOutermostSidebar', (global, action, payload) => {
  return {
    ...global,
    isOutermostSidebar: payload,
  };
});

addActionHandler('setThemeSettings', (global, actions, payload: { theme: ThemeKey } & Partial<IThemeSettings>) => {
  const { theme, ...settings } = payload;

  return replaceThemeSettings(global, theme, settings);
});
addActionHandler('requestNewNextSettingsScreen', (global, actions, payload) => {
  const { screen, foldersAction, tabId = getCurrentTabId() } = payload;
  return updateTabState(global, {
    nextSettingsScreen: screen,
    nextFoldersAction: foldersAction,
  }, tabId);
});
addActionHandler('requestNextSettingsScreen', (global, actions, nextScreen) => {
  return {
    ...global,
    settings: {
      ...global.settings,
      nextScreen,
    },
  };
});
