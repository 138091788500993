export interface InitProps {
  groupId: number;
  groupType: number;
  userId: number;
  // userRole: LiveRole;
  userName: string;
  userVipLevel?: number;
  firstName?: string;
  lastName?: string;
  isPC: boolean;
  authKey:string | bigint;
}
export enum LiveRole {
  anchor,
  viewers,
}

export enum DevicesCode {
  InitError,
  NoVideo,
  NoAudio,
}

export const UPSTREAM_EVENT = {
  CONNECT_SUCCESS: 1, // 链接已创建
  DEVICES: 2, // 设备获取
  SWITCH_CAMERA: 3, // 切换设备
  DEVICES_STATE: 23,
  UPDATE_ROOM_STREAM: 4, // 推拉流id
  VIDEO_LOAD_SUCCESS: 5, // 播放器加载状态
  VIDEO_PLAY_END: 6, // 播放结束

  WEBRTC_CONNECT_STATE: 7, // webrtc 推流状态

  USER_ANCHOR_VERIFICATION: 8243, // 开启直播权限校验
  USER_LOGIN: 8192, // 用户登录直播间
  USER_LOGOUT: 8193, // 用户退出登录
  USER_JOIN_ROOM: 8203, // 用户加入直播间
  USER_OUT_ROOM: 8204, // 用户退出直播
  SEND_DO_LIKE: 8241, // 用户点赞
  SEND_COMMENT: 8195, // 用户發送聊天消息
  GET_GROUP_ROOM_LIST: 8242, // 获取群直播列表
  GET_ROOM_INFO: 8194, // 获取直播间信息详情
  GET_ROOM_USERS: 8244, // 房间在线用户信息
  ROOM_STATE: 8245, // 主播上下麦消息
  ON_LINE: 8258,
};
export interface RoomInfo {
  groupId: number;
  userId: number;
  roomType: number;
  roomId: number;
  roomName: string;
  roomCoverPage?: string;
  roomPushUrl?:string;
  streamId?:string;
  userName: string;
  userImgName?: string;
}

export interface RoomList {
  allGroupRoomList: RoomInfo[];
}

export interface GetRoomInfo {
  cmd: number;
  myClientId: 0;
  myClientVer: 0;
  myRoomId: number;
  liveStatus: number; // 0 已結束, 1 直播中
  myUserId: number;
  radiusResponse: {
    nullmsg: string;
    onLineAllUserInfo: RoomUser[];
    dolikeCount: {
      roomId: string;
      likesCount: number;
    };
    userStatisInfo: {
      roomId: string;
      onlineUserCount: number;
      watchUserCount: number;
    };
    publicChatMsgArray?: MsgInfo[];
    userInInfo?: RoomUser[];
    userOutInfo?: RoomUser[];
    mikeTimeInfo?: MikeTimeInfo;
  };
}

export interface MikeTimeInfo {
  userId: number;
  userName: string;
  userImg: string;
  roomId: string;
  roomType: number;
  mikeStartTime: number;
  mikeDuration: number;
}

export interface InRoomUser {
  userId?: number;
  userName?: string;
}

export interface JoinRoomRes {
  cmd: number;
  myClientId: number;
  myClientVer: number | string;
  myRoomId: number | string;
  myUserId: number;
  radiusResponse: {
    nullmsg: string;
    userInInfo: RoomUser[];
    roomInfo: {
      pullUrl: string;
      pushUrl: string;
      roomId: string;
      roomName:string;
      roomType: number;
      streamId: string;
    };
    dolikeCount: {
      roomId: string;
      likesCount: number;
    };
    // 'roomAvSetting': {
    //   'audioBitRate': 32000;
    //   'audioChannels': 2;
    //   'audioSample': 44100;
    //   'audioType': 1;
    //   'videoFrame': 7;
    //   'videoHeight': 300;
    //   'videoQuality': 12;
    //   'videoType': 0;
    //   'videoWidth': 400;
    // };
  };
}

export interface RoomUser {
  isZhubo?: number;
  mobileId: number;
  roomId: string;
  userFlag: number;
  userId: number;
  userImage: string;
  userLevel: number;
  userName: string;
  userType: number;
}

export interface MsgInfo {
  sendUserVIPLevel?: number;
  msgType?: number;
  msgId?: number;
  msgTime?: number;
  sendUserId?: string;
  sendUserName?: string;
  recvUserId?: number;
  roomId?: string;
  infoSize?: number;
  messageConten: string;
  type?: string;
}

export interface OnLineAllUserInfo {
  'myUserId': number;
  'myRoomId': string;
  'radiusResponse': {
    'nullmsg': string;
    'onlineUsersCount': number;
    'watchUserCount': number;
    'onLineAllUserInfo': RoomUser[];
  };
}
