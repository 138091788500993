import type { FC } from '../../lib/teact/teact';
import React, { useCallback } from '../../lib/teact/teact';

import type { ApiChat, ApiUser } from '../../api/types';

import buildClassName from '../../util/buildClassName';

import Link from '../ui/Link';
import { getActions, getGlobal } from '../../global';

type OwnProps = {
  className?: string;
  sender?: ApiUser | ApiChat;
  children: React.ReactNode;
};

const UserLink: FC<OwnProps> = ({
  className, sender, children,
}) => {
  const { openChat } = getActions();
  const { canShowContacts } = getGlobal()

  const handleClick = useCallback(() => {
    // 权限控制聊天窗口中的用户链接不可点击
    if (sender && canShowContacts) {
      openChat({ id: sender.id });
    }
  }, [sender, openChat]);

  if (!sender) {
    return children;
  }

  return (
    <Link className={buildClassName('UserLink', className)} onClick={handleClick}>{children}</Link>
  );
};

export default UserLink;
