import type { FC } from '../../../lib/teact/teact';
import React, { useCallback } from '../../../lib/teact/teact';
import { getActions, withGlobal } from '../../../global';

import type { ApiChat, ApiUser } from '../../../api/types';
import { ApiMessageEntityTypes } from '../../../api/types';

import { selectChat, selectCurrentMessageList, selectUser } from '../../../global/selectors';
import { fetchChatByUsername, handleGroupAccessClick } from '../../../global/actions/api/chats';
import { isChatSuperGroup } from '../../../global/helpers';

type OwnProps = {
  userId?: string;
  username?: string;
  children: React.ReactNode;
};

type StateProps = {
  userOrChat?: ApiUser | ApiChat;
  isSuperGroup: boolean;
  chatId: string;
};

const MentionLink: FC<OwnProps & StateProps> = ({
  userId,
  username,
  userOrChat,
  children,
  isSuperGroup,
  chatId
}) => {
  const {
    openChat,
    openChatByUsername,
  } = getActions();


  const handleClick = async () => {
    if (username) {
      const user = await fetchChatByUsername(username.substring(1));
      if (user) {
        handleGroupAccessClick(isSuperGroup, chatId, user?.id);
      }
    }

    // if (userOrChat) {
    //   openChat({ id: userOrChat.id });
    // } else if (username) {
    //   if (!window.userRights.showMember) return;
    //   openChatByUsername({ username: username.substring(1) });
    // }
  };

  return (
    <a
      onClick={handleClick}
      className="text-entity-link"
      dir="auto"
      data-entity-type={userId ? ApiMessageEntityTypes.MentionName : ApiMessageEntityTypes.Mention}
    >
      {children}
    </a>
  );
};

export default withGlobal<OwnProps>(
  (global, { userId }): StateProps => {
    const { chatId } = selectCurrentMessageList(global) || {};
    const chat = selectChat(global, chatId);
    const isSuperGroup = Boolean(chat) && isChatSuperGroup(chat);
    return {
      userOrChat: userId ? selectUser(global, userId) : undefined,
      isSuperGroup,
      chatId
    };
  },
)(MentionLink);
