import type { LangCode } from '../../../types';

// 推荐语言
export function getSuggestedLanguage() {
  // 默认从浏览器去拿语言
  let suggestedLanguage = navigator.language;

  if (suggestedLanguage && suggestedLanguage !== 'pt-br') {
    suggestedLanguage = suggestedLanguage.substr(0, 2);
  }

  return suggestedLanguage as LangCode;
}
