import { addActionHandler } from '../../index';

// 设置 broswerID 的值，没有的话，默认false
addActionHandler('setBrowserIDVal', (global, actions, payload) => {
  // const { browserIDVal } = payload;
  // debugger
  let newGlobal = {
    ...global,
    browserIDVal: payload
  }
  // console.log('newGlobal');
  // console.log(newGlobal);


  return newGlobal
})

// 是否是客户端启动
addActionHandler('setIsClientStartup', (global, actions, payload) => {
  const { clientStartup } = payload
  return {
    ...global,
    isClientStartup: clientStartup
  }
})

// 是否支持邀请码
addActionHandler('setHasInviteCode', (global, actions, payload) => {
  const { hasInviteCode } = payload
  return {
    ...global,
    hasInviteCode: hasInviteCode
  }
})

// 是否可以举报
addActionHandler('setCanReport', (global, actions, payload) => {
  const { canReport } = payload
  return {
    ...global,
    isCanReport: canReport
  }
})

// 是否需要上传聊天记录
addActionHandler('setNeedUploadChatContent', (global, actions, payload) => {
  const { needUploadChatContent } = payload
  return {
    ...global,
    needUploadChatContent: needUploadChatContent
  }
})

//  下面三个合并成一个canShowContacts来显示隐藏
//   canShowContacts: false, // 是否显示联系人(对应权限1)
//   canChatWithOthers: false, // 是否可以和其他人聊天
//   canLookOverOthers: false, // 是否可以查看其他人信息(对应权限2)

//   canSearch: false, // 是否可以查询(对应权限3)
//   canShowAllChannelsUsers: false, // 是否需要过滤聊天列表(对应权限4)

addActionHandler('setLeftBanner', (global, actions, payload) => {
  const { list } = payload
  return {
    ...global,
    leftBanner: list
  }
})


// 设置权限1
addActionHandler('setCanShowContacts', (global, actions, payload) => {
  // const { error } = payload!;
  // debugger
  return {
    ...global,
    canShowContacts: payload,
  };
});

// 设置联系人菜单是否显示
addActionHandler('setNeedShowContacts', (global, actions, payload) => {
  return {
    ...global,
    needShowContacts: payload
  }
})

// 设置是否正常查看和访问他人手机号码
addActionHandler('setNeedShowPhone', (global, actions, payload) => {
  return {
    ...global,
    needShowPhone: payload
  }
})

// 设置权限 聊天列表头部搜索是否显示
addActionHandler('setCanSearch', (global, actions, payload) => {
  // const { error } = payload!;

  return {
    ...global,
    canSearch: payload,
  };
});

// 设置权限 是否过滤白名单
addActionHandler('setCanShowAllChannelsUsers', (global, actions, payload) => {
  // const { error } = payload!;

  return {
    ...global,
    canShowAllChannelsUsers: payload,
  };
});

// 设置权限 是否删除聊天记录
addActionHandler('setCanDeleteHistory', (global, actions, payload) => {
  return {
    ...global,
    canDeleteHistory: payload
  }
})

// 设置权限 是否可以同时删除他人和自己的聊天记录
addActionHandler('setCanDeleteOther', (global, actions, payload) => {
  return {
    ...global,
    canDeleteOther: payload
  }
})

// 设置权限 屏蔽其他人
addActionHandler('setCanBlock', (global, actions, payload) => {
  return {
    ...global,
    canBlock: payload
  }
})

// 设置权限 联系人子菜单是否过滤白名单（子功能）（ 0 表示 上面不显示 联系人白名过滤 1 表示上面显示 联系人白名过滤 2 表示 上面不显示， 联系人白名不过滤 3，表示 表示上面显示 联系人白名不过滤）
addActionHandler('setSubShowContactsValue', (global, actions, payload) => {
  const { subShowContactsValue } = payload
  return {
    ...global,
    subShowContactsValue: subShowContactsValue
  }
})

// wss设置新的白名单列表
addActionHandler('setNewWhiteList', (global, actions, payload) => {
  const { list, isNew } = payload
  if (isNew) {
    return {
      ...global,
      newWhiteList: [...global.newWhiteList,list]
    }
  } else {
    return {
      ...global,
      newWhiteList: list
    }
  }
})

// wss通知是否被封号
// 踢人/用户已经被禁止登录
addActionHandler('setBlockadeAccount', (global, actions, payload) => {
  return {
    ...global,
    isBlockadeAccount: payload
  }
})

// 设置在线客服名单列表
addActionHandler('setOnlineCustomerServiceList', (global, actions, payload) => {
  const { list } = payload
  return {
    ...global,
    onlineCustomerServiceList: list
  }
})

// 记录临时邀请码
addActionHandler('setTemporaryInviteCode', (global, actions, payload) => {
  // debugger
  const { code } = payload;

  return {
    ...global,
    temporaryInviteCode: code
  }
})

// 是否展示填写邀请码弹窗
addActionHandler('setIsWriteInviteCodeModalOpen', (global, actions, payload) => {
  return {
    ...global,
    isWriteInviteCodeModalOpen: payload
  }
})

// 记录临时推荐码
addActionHandler('setTemporaryRecommendCode', (global, actions, payload) => {
  const { code } = payload;

  return {
    ...global,
    temporaryRecommendCode: code
  }
})


// 是否展示填写推荐码弹窗
addActionHandler('setIsWriteRecommendCodeModalOpen', (global, actions, payload) => {
  return {
    ...global,
    isWriteRecommendCodeModalOpen: payload
  }
})

// 设置我的客户列表
addActionHandler('setCustomerList', (global, actions, payload) => {
  const { list, pageNum, total } = payload
  // debugger
  // if (total) {
  //   computPageNum(total)
  // }
  // if (isNewPage) {}
  if (total) {
    return {
      ...global,
      customerList: list,
      customerPageNum: pageNum ? pageNum : 1,
      customerLastPageNum: total ? computLastPageNum(total) : 1,
      customerTotal: total ? total : 0,
    }
  } else {
    return {
      ...global,
      customerList: list,
      customerPageNum: pageNum ? pageNum : 1,
    }
  }
  // return {
  //   ...global,
  //   customerList: list,
  //   customerPageNum: pageNum ? pageNum : 1,
  //   customerLastPageNum: total ? computLastPageNum(total) : 1,
  //   customerTotal: total ? total : 0,
  // }
})


// 计算最后一页码数
function computLastPageNum (total: number) {
  let pageSize = 20;
  let lastPageNum = Math.floor(total/pageSize) + 1;
  return lastPageNum
}
