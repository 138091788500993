import React, { memo, useCallback } from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';
import convertPunycode from '../../lib/punycode';

import type { FC } from '../../lib/teact/teact';
import { ApiCustomConfig, ApiMessageEntityTypes } from '../../api/types';

import {
  DEBUG, SHARE_CUSTOM_SPEATOR,
} from '../../config';
import buildClassName from '../../util/buildClassName';
import { ensureProtocol } from '../../util/ensureProtocol';
import { openShareLink } from '../../util/toolUtils';

type OwnProps = {
  url?: string;
  text: string;
  className?: string;
  children?: React.ReactNode;
  isRtl?: boolean;
};
type StateProps = {
  customConfig: ApiCustomConfig | undefined
}
const SafeLink: FC<OwnProps & StateProps> = ({
  url,
  text,
  className,
  children,
  isRtl,
  customConfig,
}) => {
  const { openUrl,openTelegramLink } = getActions();

  const content = children || text;
  const isSafe = url === content;

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!url) return true;
    e.preventDefault();
    if(customConfig && customConfig.link && url.indexOf(customConfig.link) > -1) {
      const speatorIndex = customConfig.link.lastIndexOf('/');
      const customSpeator = customConfig.link.substring(speatorIndex, customConfig.link.length);
      openShareLink(url, customSpeator);
    } else {
      openUrl({ url, shouldSkipModal: isSafe });
    }
    return false;
  }, [isSafe, openUrl, url]);

  if (!url) {
    return undefined;
  }

  const classNames = buildClassName(
    className || 'text-entity-link',
    text.length > 50 && 'long-word-break-all',
  );

  return (
    <a
      href={ensureProtocol(url)}
      title={getDomain(url)}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames}
      onClick={handleClick}
      dir={isRtl ? 'rtl' : 'auto'}
      data-entity-type={ApiMessageEntityTypes.Url}
    >
      {content}
    </a>
  );
};

function getDomain(url?: string) {
  if (!url) {
    return undefined;
  }

  const href = ensureProtocol(url);
  if (!href) {
    return undefined;
  }

  try {
    let decodedHref = decodeURI(href.replace(/%%/g, '%25'));

    const match = decodedHref.match(/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i);
    if (!match) {
      return undefined;
    }
    const domain = match[1];
    decodedHref = decodedHref.replace(domain, convertPunycode(domain));

    return decodedHref;
  } catch (error) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.error('SafeLink.getDecodedUrl error ', url, error);
    }
  }

  return undefined;
}
export default memo(withGlobal(
  (global): StateProps => {
      const { customConfig } = global;
      return {
        customConfig
      }
  }
)(SafeLink));
